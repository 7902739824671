import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'jotai';
import { Row, Spin } from 'antd';

import 'antd/dist/antd.css';
import '../shared/styles/globals.css';

const TheLayout = React.lazy(() => import('../containers/TheLayout'));

export default function App() {
  return (
    <Provider>
      <HashRouter>
        <React.Suspense fallback={<Row justify='center' style={{ 'marginTop': '35%' }}><Spin size='large' /></Row>}>
          <Switch>
            <Route render={props => <TheLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    </Provider>
  );
}
